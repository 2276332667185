import { useIntl } from 'react-intl';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import FormWrapper from '../../FormWrapper/FormWrapper';
import CheckboxList from '../../CheckboxList/CheckboxList';
import SelectFormRow from '../../SelectFormRow/SelectFormRow';
import InputRow from '../../InputRow/InputRow';
import { isFloat } from '../../../../utils/validation.util';

import {
  PortAddFormProps,
  PortDetailsFormType,
} from '../../../../models/admin-form-types';

import { RootStore } from '../../../../store';

import config from '../../../../configs/config';

import '../../shared.scss';

const daysIds = [
  {
    fieldKey: 'workingOnMonday',
    label: 'monday-label',
  },
  {
    fieldKey: 'workingOnTuesday',
    label: 'tuesday-label',
  },
  {
    fieldKey: 'workingOnWednesday',
    label: 'wednesday-label',
  },
  {
    fieldKey: 'workingOnThursday',
    label: 'thursday-label',
  },
  {
    fieldKey: 'workingOnFriday',
    label: 'friday-label',
  },
  {
    fieldKey: 'workingOnSaturday',
    label: 'saturday-label',
  },
  {
    fieldKey: 'workingOnSunday',
    label: 'sunday-label',
  },
];

/**
 * Float validator
 * @param value value
 */
export const floatValidator = (
  value: string,
  min: number,
  max: number,
): boolean => {
  const floatChecking = isFloat(value);
  if (!floatChecking) {
    return true;
  }

  const num = parseFloat(value);
  if (num < min || num > max) {
    return true;
  }

  return false;
};

const PortDetailsForm = ({
  stockpoints,
  formValue,
  handleValueChange,
}: PortAddFormProps) => {
  const intl = useIntl();
  const { countries } = useSelector((state: RootStore) => state.lookups);

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const title = getTranslatedMessage('port-details-label');
  const workingDaysNoticeOptions = [
    ..._.range(1, 21).map((v) => v.toString()),
    'STE',
    `3 (4 ${getTranslatedMessage('for-bulk-label')})`,
  ];
  const days = daysIds.map((day) => ({
    fieldKey: day.fieldKey,
    label: intl.formatMessage({ id: day.label }),
    checked: !!formValue[day.fieldKey as keyof PortDetailsFormType],
  }));

  return (
    <FormWrapper title={title}>
      <div className="columnRow">
        <SelectFormRow
          labelId="stockpoint-name-label"
          value={formValue.stockpoint || ''}
          onChange={(value: string) => handleValueChange('stockpoint', value)}
          options={stockpoints?.map((stockpoint) => ({
            name: stockpoint.name,
            value: stockpoint,
          }))}
          tooltip={getTranslatedMessage('stockpoint-name-tooltip')}
        />
        <InputRow
          labelId="port-name-label"
          value={formValue.name}
          onChange={(value: string) => handleValueChange('name', value)}
          tooltip={getTranslatedMessage('port-name-tooltip')}
        />
        <InputRow
          labelId="port-erp-label"
          value={formValue.erpId}
          onChange={(value: number) => handleValueChange('erpId', value)}
          tooltip={getTranslatedMessage('port-erp-tooltip')}
        />
      </div>
      <div className="columnRow">
        <SelectFormRow
          labelId="country-label"
          value={formValue.country}
          onChange={(value: string) => handleValueChange('country', value)}
          options={countries.map((c) => ({ name: c.name, value: c }))}
          tooltip={getTranslatedMessage('country-tootlip')}
          disabled={true}
        />
        <InputRow
          labelId="latitude-label"
          value={formValue.latitude}
          onChange={(value: string) => handleValueChange('latitude', value)}
          tooltip={getTranslatedMessage('latitude-tooltip')}
          customValidator={(value) => floatValidator(value, -90, 90)}
        />
        <InputRow
          labelId="longitude-label"
          value={formValue.longitude}
          onChange={(value: string) => handleValueChange('longitude', value)}
          tooltip={getTranslatedMessage('longitude-tooltip')}
          customValidator={(value) => floatValidator(value, -180, 180)}
        />
        <InputRow
          labelId="gmt-label"
          value={formValue.gmtOffsetInHours}
          onChange={(value: string) =>
            handleValueChange('gmtOffsetInHours', value)
          }
          tooltip={getTranslatedMessage('gmt-tooltip')}
          customValidator={(value) => floatValidator(value, -11, 12)}
        />
      </div>
      <div className="columnRow">
        <InputRow
          labelId="friendly-url-name-label"
          value={formValue.friendlyUrl}
          onChange={(value: string) => {
            const updatedValue = value.replace(
              config.FRIENDLY_URL_NAME_REGEX,
              '',
            );
            handleValueChange('friendlyUrl', updatedValue);
          }}
        />
        <SelectFormRow
          labelId="working-days-notice"
          value={formValue.workingDaysNotice}
          onChange={(value: string) =>
            handleValueChange('workingDaysNotice', value)
          }
          options={workingDaysNoticeOptions.map((option) => ({
            name: option,
            value: option,
          }))}
          tooltip={getTranslatedMessage('working-days-notice-tooltip')}
        />
      </div>
      <CheckboxList
        labelId="working-days-in-country"
        items={days}
        onClick={(fieldKey: string) => {
          handleValueChange(
            fieldKey,
            !formValue[fieldKey as keyof PortDetailsFormType],
          );
        }}
        tooltip={getTranslatedMessage('working-days-in-country-tooltip')}
      />
    </FormWrapper>
  );
};

export default PortDetailsForm;
