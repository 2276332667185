import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { CaseStudyFull } from '../../../models/case-study';

import { downloadCaseStudyDocument } from '../../../services/CaseStudyService';

import { hideLoading, showLoading } from '../../../features/loadingSlice';
import { addToast } from '../../../features/toastSlice';

import styles from './ProductCaseStudies.module.scss';
import _ from 'lodash';
import { RootStore } from '../../../store';

type ProductCaseStudiesProps = {
  caseStudies: CaseStudyFull[];
  isAdmin: boolean;
  onDeleteCaseStudy?: (val: number) => void;
  showProduct?: boolean;
  userRole?: string;
};

const ProductCaseStudies = ({
  caseStudies,
  isAdmin,
  onDeleteCaseStudy,
  showProduct,
  userRole, //Internal User
}: ProductCaseStudiesProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const {
    applications,
    subApplications,
    sectors,
    subSectors,
    countries,
    benefits,
  } = useSelector((state: RootStore) => state.lookups);

  const getUserVisibility = (caseStudy: CaseStudyFull) => {
    const userVisibility: string[] = [];
    if (caseStudy.visibleToInternalAndApproverUsers) {
      userVisibility.push(
        getTranslatedMessage('case-details-visibility-others'),
      );
    }
    if (caseStudy.visibleToDistributorUsers) {
      userVisibility.push(
        getTranslatedMessage('case-details-visibility-distributor'),
      );
    }
    if (caseStudy.visibleToAnonymousUsers) {
      userVisibility.push(
        getTranslatedMessage('case-details-visibility-anonymous'),
      );
    }

    return userVisibility.join(', ');
  };

  const downloadDocument = (caseStudyId: number) => {
    dispatch(showLoading());
    downloadCaseStudyDocument(caseStudyId)
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('download-case-study-document-failure'),
            message: '',
          }),
        );
      })
      .finally(() => dispatch(hideLoading()));
  };
  const idsToNames = (id: number | number[], entities: any[]) => {
    if (!id) {
      return null;
    }

    const ids = _.isNumber(id) ? [id as number] : id;
    return _(entities)
      .filter((x) => _.includes(ids, x.id))
      .map('name')
      .filter(_.identity)
      .sort()
      .join(', ');
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>
          <FormattedMessage id="case-studies-label" />
        </h3>
      </div>
      <div className={styles.content}>
        <div className={styles.contentHeader}>
          <span className={styles.width10}>
            <FormattedMessage id="case-details-title" />
          </span>
          {showProduct && (
            <span className={styles.width10}>
              <FormattedMessage id="product-name-label" />
            </span>
          )}

          <span className={styles.width10}>
            <FormattedMessage id="product-application-label" />
          </span>
          {userRole === 'Internal User' && (
            <span className={styles.width10}>
              <FormattedMessage id="product-sub-application-label" />
            </span>
          )}
          <span className={styles.width10}>
            <FormattedMessage id="product-replacement-sector-label" />
          </span>
          <span className={styles.width10}>
            <FormattedMessage id="add-bulk-products-sub-sector-label" />
          </span>
          <span className={styles.width10}>
            <FormattedMessage id="case-details-country" />
          </span>
          <span className={styles.width10}>
            <FormattedMessage id="product-status-Benefits-label" />
          </span>
          {userRole === 'Internal User' && (
            <>
              <span className={styles.width10}>
                <FormattedMessage id="case-details-customer" />
              </span>
              <span className={styles.width10}>
                <FormattedMessage id="case-details-competitor" />
              </span>
              <span className={styles.width10}>
                <FormattedMessage id="case-details-location" />
              </span>
            </>
          )}
          <span className={styles.width10}>
            <FormattedMessage id="case-details-study" />
          </span>
          {/* {isAdmin && <span></span>} */}
        </div>
        <div className={styles.caseStudiesContainer}>
          <div className={styles.desktopContainer}>
            {caseStudies.map((caseStudy) => (
              <div className={styles.caseStudy} key={caseStudy.id}>
                <span className={styles.width10}>{caseStudy.title}</span>
                {showProduct && (
                  <span className={styles.width10}>
                    {caseStudy.product?.name}
                  </span>
                )}
                <span className={styles.width10}>
                  {idsToNames(
                    _.map(caseStudy?.caseStudyApplications, 'applicationId'),
                    applications,
                  )}
                </span>
                {userRole === 'Internal User' && (
                  <span className={styles.width10}>
                    {idsToNames(
                      _.map(
                        caseStudy?.caseStudySubApplications,
                        'applicationId',
                      ),
                      subApplications,
                    )}
                  </span>
                )}
                <span className={styles.width10}>
                  {idsToNames(
                    _.map(caseStudy?.caseStudySectors, 'sectorId'),
                    sectors,
                  )}
                </span>
                <span className={styles.width10}>
                  {idsToNames(
                    _.map(caseStudy?.caseStudySubSectors, 'subSectorId'),
                    subSectors,
                  )}
                </span>
                <span className={styles.width10}>
                  {idsToNames(
                    _.map(caseStudy?.caseStudycountries, 'countryId'),
                    countries,
                  )}
                </span>
                <span className={styles.width10}>
                  {idsToNames(
                    _.map(caseStudy?.caseStudyBenefits, 'benefitId'),
                    benefits,
                  )}
                </span>
                {userRole === 'Internal User' && (
                  <>
                    <span className={styles.width10}>{caseStudy.customer}</span>
                    <span className={styles.width10}>
                      {caseStudy.competitor}
                    </span>
                    <span className={styles.width10}>{caseStudy.location}</span>
                  </>
                )}
                {/* <div
                  className={classNames(
                    styles.attributesContainer,
                    styles.width40,
                  )}
                >
                  <div className={styles.attributes}>
                    <div>
                      {idsToNames(
                        _.map(
                          caseStudy?.caseStudyApplications,
                          'applicationId',
                        ),
                        applications,
                      )}
                    </div>
                    <div>
                      {idsToNames(
                        _.map(
                          caseStudy?.caseStudySubApplications,
                          'subApplicationId',
                        ),
                        subApplications,
                      )}
                    </div>
                    <div>
                      {idsToNames(
                        _.map(caseStudy?.caseStudySectors, 'sectorId'),
                        sectors,
                      )}
                    </div>
                    <div>
                      {idsToNames(
                        _.map(caseStudy?.caseStudySubSectors, 'subSectorId'),
                        subSectors,
                      )}
                    </div>
                  </div>
                </div> */}
                {/* <span className={styles.width10}>
                  {getUserVisibility(caseStudy)}
                </span> */}
                <span
                  className={classNames(
                    styles.width10,
                    styles.documentDownloadLink,
                  )}
                  onClick={() => downloadDocument(caseStudy.id)}
                >
                  {caseStudy.document.name}
                </span>
                {/* {isAdmin && (
                  <span
                    className={classNames(styles.documentDownloadLink)}
                    onClick={() =>
                      onDeleteCaseStudy && onDeleteCaseStudy(caseStudy.id)
                    }
                  >
                    <Trash />
                  </span>
                )} */}
              </div>
            ))}
          </div>
          <div className={styles.mobileContainer}>
            {caseStudies.map((caseStudy) => (
              <div className={styles.caseStudy} key={caseStudy.id}>
                <div className={styles.row}>
                  <div className={styles.flexSpaceBetween}>
                    <span className={styles.label}>
                      <FormattedMessage id="case-details-title" />
                    </span>
                    {/* {isAdmin && (
                      <Trash
                        onClick={() =>
                          onDeleteCaseStudy && onDeleteCaseStudy(caseStudy.id)
                        }
                      />
                    )} */}
                  </div>
                  <span className={styles.value}>{caseStudy.title}</span>
                  <div className={styles.flexSpaceBetween}>
                    <span className={styles.label}>
                      <FormattedMessage id="product-application-label" />
                    </span>
                  </div>
                  <span className={styles.value}>
                    {idsToNames(
                      _.map(caseStudy?.caseStudyApplications, 'applicationId'),
                      applications,
                    )}
                  </span>
                  <div className={styles.flexSpaceBetween}>
                    <span className={styles.label}>
                      <FormattedMessage id="product-replacement-sector-label" />
                    </span>
                  </div>
                  <span className={styles.value}>
                    {idsToNames(
                      _.map(caseStudy?.caseStudySectors, 'sectorId'),
                      sectors,
                    )}
                  </span>
                  <div className={styles.flexSpaceBetween}>
                    <span className={styles.label}>
                      <FormattedMessage id="add-bulk-products-sub-sector-label" />
                    </span>
                  </div>
                  <span className={styles.value}>
                    {idsToNames(
                      _.map(caseStudy?.caseStudySubSectors, 'subSectorId'),
                      subSectors,
                    )}
                  </span>
                  <div className={styles.flexSpaceBetween}>
                    <span className={styles.label}>
                      <FormattedMessage id="case-details-country" />
                    </span>
                  </div>
                  <span className={styles.value}>
                    {idsToNames(
                      _.map(caseStudy?.caseStudycountries, 'countryId'),
                      countries,
                    )}
                  </span>
                  <div className={styles.flexSpaceBetween}>
                    <span className={styles.label}>
                      <FormattedMessage id="product-status-Benefits-label" />
                    </span>
                  </div>
                  <span className={styles.value}>
                    {idsToNames(
                      _.map(caseStudy?.caseStudyBenefits, 'benefitId'),
                      benefits,
                    )}
                  </span>
                </div>
                {/* <div className={styles.row}>
                  <span className={styles.label}>
                    <FormattedMessage id="case-details-attributes" />
                  </span>
                  <div className={classNames(styles.value, styles.attributes)}>
                    <div>
                      <span className={styles.label}>
                        <FormattedMessage id="case-details-customer" />
                      </span>
                      {caseStudy.customer}
                    </div>
                    <div>
                      <span className={styles.label}>
                        <FormattedMessage id="case-details-machine" />
                      </span>
                      {caseStudy.machine}
                    </div>
                    <div>
                      <span className={styles.label}>
                        <FormattedMessage id="case-details-location" />
                      </span>
                      {caseStudy.location}
                    </div>
                    <div>
                      <span className={styles.label}>
                        <FormattedMessage id="case-details-competitor" />
                      </span>
                      {caseStudy.competitor}
                    </div>
                  </div>
                </div>
                <div className={styles.row}>
                  <span className={styles.label}>
                    <FormattedMessage id="case-details-user-visibility" />
                  </span>
                  <span className={styles.value}>
                    {getUserVisibility(caseStudy)}
                  </span>
                </div>
                */}
                <div className={styles.row}>
                  <span className={styles.label}>
                    <FormattedMessage id="case-details-upload-study" />
                  </span>
                  <span
                    className={styles.documentDownloadLink}
                    onClick={() => downloadDocument(caseStudy.id)}
                  >
                    {caseStudy.document.name}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCaseStudies;
