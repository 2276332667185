import styles from './Header.module.scss';
import UserProfile from './UserProfile/UserProfile';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import HeaderMenu from './HeaderMenu/HeaderMenu';
import { useState } from 'react';
import { showGenericModal } from '../../../features/genericModalSlice';
import { ReactComponent as ErrorRedIcon } from '../../../icons/error-red.svg';
import { ReactComponent as RightArrowIcon } from '../../../icons/arrow-right-gray.svg';
import { FormattedMessage } from 'react-intl';

/**
 * main header component
 */
function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { welcomeInfo, hasWelcomeInfo } = useSelector(
    (state: RootStore) => state.welcomeInfo,
  );
  const { hasLogin, isAdmin, user } = useSelector(
    (state: RootStore) => state.login,
  );
  const { unsaved } = useSelector((state: RootStore) => state.genericModal);

  const [showMenu, setShowMenu] = useState(false);
  const [openAdminMenu, setOpenAdminMenu] = useState(false);

  const navigateTo = (url: string) => {
    if (unsaved) {
      dispatch(
        showGenericModal({
          type: 'confirmation',
          titleId: 'unsaved-changes-label',
          messageId: 'unsaved-changes-message',
          to: url,
        }),
      );
    } else {
      navigate(url);
    }
  };

  return (
    <div>
      <div className={styles.container}>
        <div onClick={() => navigateTo('/')} className={styles.link}>
          <img alt={'logo'} src={'/images/brand_logo.jpeg'} />
        </div>

        {hasWelcomeInfo && (
          <UserProfile
            welcomeInfo={welcomeInfo}
            showMenu={showMenu}
            toggleMainMenu={(state: boolean) => setShowMenu(state)}
            openAdminMenu={openAdminMenu}
            hasLogin={hasLogin}
            user={user}
            toggleAdminMenu={(state: boolean) => setOpenAdminMenu(state)}
          />
        )}
      </div>
      {!hasLogin &&
        !location.pathname.startsWith('/login') &&
        welcomeInfo?.industrySector?.friendlyUrl === 'industrial' && (
          <div className={styles.notLoginContainer}>
            <div className={styles.loggedOutInfo}>
              <ErrorRedIcon />
              <FormattedMessage id={'login-tooltip'} />
            </div>
            <div
              className={styles.loginHere}
              onClick={() => navigateTo('/login')}
            >
              <FormattedMessage id={'login-here'} />
              <RightArrowIcon />
            </div>
          </div>
        )}
      {hasLogin && (
        <HeaderMenu
          userRole={user?.role || ''}
          sectionId={user?.sectorAdminSectorId || 0}
          openAdminMenu={openAdminMenu}
          toggleAdminMenu={(state: boolean) => {
            setShowMenu(false);
            setOpenAdminMenu(state);
          }}
        />
      )}
    </div>
  );
}

export default Header;
