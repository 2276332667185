import styles from './ProductLeftDetail.module.scss';
import IconField from '../../common/IconField/IconField';
import { useIntl } from 'react-intl';
import { PropsWithoutRef, useMemo } from 'react';
import { ProductDetails } from '../../../models/search-result-product';
import { ReactComponent as ApprovalGreen } from '../../../icons/approval-green.svg';
import { ReactComponent as ListGreen } from '../../../icons/list-green.svg';
import { ReactComponent as SettingsGreen } from '../../../icons/settings-green.svg';
import { ReactComponent as DocumentGreen } from '../../../icons/document-green.svg';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { mapLookupItemsDictionary } from '../../../utils/lookup.util';
import ReactTooltip from 'react-tooltip';

interface ProductLeftDetailProps {
  productDetails: ProductDetails;
  casestudies?: string;
}

/**
 * product left drawer contents
 * @param product product
 */
function ProductLeftDetail({
  productDetails,
  casestudies = '0',
}: PropsWithoutRef<ProductLeftDetailProps>) {
  const intl = useIntl();
  const { hasLogin } = useSelector((state: RootStore) => state.login);

  const { categoriesById, applicationsById, subCategories, subApplications } =
    useSelector((rootState: RootStore) => rootState.lookups);

  const categoriesText = useMemo(() => {
    return `${productDetails.product.productCategories
      .map((category) => categoriesById[category.categoryId]?.name || '')
      .join(', ')}`;
  }, [productDetails, categoriesById]);

  const applicationsText = useMemo(() => {
    const map = mapLookupItemsDictionary(subCategories);
    return `${productDetails.product.productApplications
      .map(
        (application) =>
          applicationsById[application.applicationId]?.name || '',
      )
      .join(', ')}${
      productDetails.product.productSubApplications?.length > 0 ? ', ' : ''
    }${productDetails.product.productSubApplications
      .map((application) => map[application.subApplicationId]?.name || '')
      .join(', ')}`;
  }, [productDetails, applicationsById, subApplications]);

  const subCategoriesText = useMemo(() => {
    if (!productDetails.product.subCategoryId) {
      return '';
    }

    const map = mapLookupItemsDictionary(subCategories);
    return map[productDetails.product.subCategoryId]?.name || '';
  }, [productDetails, subCategories]);

  return (
    <div className={styles.container}>
      {productDetails.product?.oemProductApprovalCount > 0 && (
        <>
          <div data-tip data-for={'oem-tooltip'} className={styles.item}>
            <IconField
              className={styles.item}
              icon={<ApprovalGreen />}
              label={intl.formatMessage({ id: 'oem-approved-label' })}
              value={intl.formatMessage(
                { id: 'approvals-counts-label' },
                {
                  count: productDetails.product?.oemProductApprovalCount,
                },
              )}
            />
            <ReactTooltip
              id={'oem-tooltip'}
              place="top"
              effect="solid"
              clickable={true}
              delayHide={1000}
              className={styles.tooltipExtended}
            >
              {/* Safe to use dangerouslySetInnerHTML here since we are reading from translation file */}
              <p
                className={styles.topDescriptions}
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'product-oem-counts-tooltip',
                  }),
                }}
              />
            </ReactTooltip>
          </div>
        </>
      )}
      <IconField
        className={styles.item}
        icon={<ListGreen />}
        label={intl.formatMessage({ id: 'product-category-label' })}
        value={categoriesText}
      />

      {subCategoriesText && (
        <IconField
          className={styles.item}
          icon={<ListGreen />}
          label={intl.formatMessage({ id: 'subcategory-label' })}
          value={subCategoriesText}
        />
      )}

      <IconField
        className={styles.item}
        icon={<SettingsGreen />}
        label={intl.formatMessage({ id: 'application-label' })}
        value={applicationsText}
      />
      {hasLogin && (
        <IconField
          className={styles.item}
          icon={<DocumentGreen />}
          label={'Case studies'}
          value={casestudies}
        />
      )}
    </div>
  );
}

export default ProductLeftDetail;
