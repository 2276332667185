import { useEffect, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import FormRow from '../../FormRow/FormRow';
import FormWrapper from '../../FormWrapper/FormWrapper';
import InputRow from '../../InputRow/InputRow';
import LanguageSelect from '../../LanguageSelect/LanguageSelect';
import TextareaRow from '../../TextareaRow/TextareaRow';

import { RootStore } from '../../../../store';

import {
  ManageFamilyTranslationFormType,
  ManageRangeTranslationFormType,
  TranslationsFormType,
} from '../../../../models/admin-form-types';

type LocalisedFamilyRangeDetailsProps = {
  form?: 'family' | 'range';
  formValue: any;
  handleValueChange: any;
};

const LocalisedFamilyRangeDetails = ({
  form = 'family',
  formValue,
  handleValueChange,
}: LocalisedFamilyRangeDetailsProps) => {
  const intl = useIntl();
  const { languages } = useSelector(
    (rootState: RootStore) => rootState.lookups,
  );
  const [activeLanguage, setActiveLanguage] = useState(0);
  const [selectedLanguages, setSelectedLanguages] = useState([
    {
      id: 0,
      empty: false,
    },
  ]);

  const languageOptions = useMemo(
    () =>
      languages.map((c) => ({
        name: c.name,
        value: c,
        disabled: activeLanguage === 0 && c.id !== 1,
      })),
    [languages, activeLanguage],
  );

  const translationEmpty = (translation: any) => {
    let isEmpty = true;
    for (const fieldKey of fieldKeys) {
      isEmpty = isEmpty && !translation[fieldKey];
      if (!isEmpty) {
        break;
      }
    }

    return isEmpty;
  };

  useEffect(() => {
    const tempSelectedLanguages = [];
    for (const language of Object.keys(formValue.translations)) {
      tempSelectedLanguages.push({
        id: Number(language),
        empty: translationEmpty(formValue.translations[language]),
      });
    }

    if (
      activeLanguage === 0 &&
      Object.keys(formValue.translations).length > 0
    ) {
      setActiveLanguage(1);
    }

    setSelectedLanguages(tempSelectedLanguages);
  }, [formValue.translations]);

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  let fieldKeys = ['description', 'shortDescription'];

  let title = getTranslatedMessage('localised-family-details-label');
  let fieldLabelId1 = 'description-label';
  let fieldLabelId2 = 'short-description-label';
  let fieldTooltipId1 = 'family-description-tooltip';
  let fieldTooltipId2 = 'family-short-description-tooltip';
  if (form === 'range') {
    title = getTranslatedMessage('localised-range-details-label');
    fieldKeys = ['tagLine', 'description'];
    fieldLabelId1 = 'range-tag-line-label';
    fieldLabelId2 = 'range-description-label';
    fieldTooltipId1 = 'range-tag-line-tooltip';
    fieldTooltipId2 = 'range-description-tooltip';
  }
  const infoMessage = getTranslatedMessage('localised-details-info');

  const handleLanguageChange = (languages: any) => {
    const selectedLanguages: string[] = languages.map(
      (language: any) => language.id,
    );
    const translations: TranslationsFormType<
      ManageFamilyTranslationFormType | ManageRangeTranslationFormType
    > = _.cloneDeep(formValue.translations);

    // If the language is not present, we just add
    for (const language of selectedLanguages) {
      if (!translations[language]) {
        translations[language] = {} as
          | ManageFamilyTranslationFormType
          | ManageRangeTranslationFormType;
        for (const key of fieldKeys) {
          translations[language][
            key as keyof (
              | ManageFamilyTranslationFormType
              | ManageRangeTranslationFormType
            )
          ] = '';
        }
      }
    }

    // If the language has been removed, we remove it
    for (const language of Object.keys(translations)) {
      const index = selectedLanguages.findIndex(
        (l) => Number(l) === Number(language),
      );
      if (index === -1) {
        delete translations[language];
      }
    }

    handleValueChange('translations', translations);
    const currentActiveTab = languages.find((l: any) => l.active);
    if (currentActiveTab) {
      setActiveLanguage(currentActiveTab.id);
    }
  };

  const handleFieldValueChange = (fieldKey: string, value: string) => {
    const translations = _.cloneDeep(formValue.translations);
    translations[activeLanguage][fieldKey] = value;
    handleValueChange('translations', translations);
  };

  return (
    <FormWrapper title={title} infoMessage={infoMessage}>
      <FormRow labelId={'oem-page-add-additional-language'} required={false}>
        <LanguageSelect
          activeLanguage={activeLanguage}
          handleActiveLanguageChange={(id: number) => setActiveLanguage(id)}
          langIds={selectedLanguages}
          languageOptions={languageOptions}
          placeholder={getTranslatedMessage('oem-page-select-placeholder')}
          onChange={handleLanguageChange}
        />
      </FormRow>
      {activeLanguage > 0 && (
        <TextareaRow
          labelId={fieldLabelId1}
          value={formValue.translations[activeLanguage][fieldKeys[0]]}
          onChange={(value: string) =>
            handleFieldValueChange(fieldKeys[0], value)
          }
          tooltip={getTranslatedMessage(fieldTooltipId1)}
          maxLength={400}
        />
      )}
      {activeLanguage > 0 && (
        <InputRow
          labelId={fieldLabelId2}
          value={formValue.translations[activeLanguage][fieldKeys[1]]}
          onChange={(value: string) =>
            handleFieldValueChange(fieldKeys[1], value)
          }
          tooltip={getTranslatedMessage(fieldTooltipId2)}
          required={false}
        />
      )}
    </FormWrapper>
  );
};

export default LocalisedFamilyRangeDetails;
